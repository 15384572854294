.searchIcons {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.searchIcon {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #dd7210;
}

.searchItem .logo a .text span {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
}

.searchSec {
    background-color: transparent;
    position: relative;
}

.sticky.active.searchSec {
    padding: 10px 0 5px;
}

.searchItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background: #fff;
}

.rightSearch {
    display: flex;
    column-gap: 20px;
}

/* Bar Start */
.searchItem .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bar svg {
    font-size: 29px;
    color: #464646;
    cursor: pointer;
    display: none;
}

/* search Info */
.searchInf {
    background-color: #f3f4f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f3f4f7;
}

.searchInf input {
    background-color: #fff;
    width: 400px;
    padding: 0 15px;
    height: 44px;
    border-radius: 0;
    font-size: 16px;
    border: 0;
    color: #131011;
}

.searchInf input:focus {
    box-shadow: none;
    border: 0;
    background-color: #efefef;
}

.rightSearchInfo {
    display: flex;
}

.allCategory {
    padding-right: 10px;
}

.allCategory select {
    background-color: transparent;
    border: 0;
    padding: 12px 8px;
    font-size: 16px;
    color: #131011;
}

.allCategory select:focus {
    outline: 0;
}

.searchIcon svg {
    font-size: 22px;
    cursor: pointer;
}

/* Call Details */
.callDetail {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.callDetail svg {
    font-size: 20px;
}

.callText h6 {
    margin: 0;
    text-transform: uppercase;
    color: gray;
    font-size: 12px;
}

.callText p {
    color: #111;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
}

.searchIconsItem ul {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin: 0;
    padding: 0;
}

.searchIconsItem ul li a {
    font-size: 18px;
    color: #1b1b1b;
    line-height: 20px;
    width: 30px;
    height: 30px;
    border: 1px solid #1b1b1b;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .4s ease-in-out;
    border-radius: 50%;
}

.searchIconsItem ul li a:hover {
    background-color: #fff;
    border-color: #dd7210;
    color: #dd7210;
}

.countParent a {
    position: relative;
}

.count {
    display: flex;
    background-color: #dd7210;
    color: #fff;
    font-size: 13px;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-weight: 600;
    position: absolute;
    top: -10px;
    right: -10px;
}


/* Trending */
.treandingSec {
    box-shadow: 0 0 8px 0 rgb(7 7 7 / 10%);
    background: #fff;
    position: absolute;
    width: 100%;
    top: 60px;
    transition: .4s ease-in-out;
    z-index: 1;
}

.trendingItem {
    border: 1px solid #e8e7e67d;
    border-top: none;
    padding: 16px 15px;
}

.trendingTitle {
    font-size: 13px;
    line-height: 1.38;
    color: #131011;
}

.trendingItem ul {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
}

.trendingItem ul li a {
    padding: 9px 16px;
    border: 1px solid #e8e7e6;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    color: #131011;
    transition: .4s ease-in-out;
    text-transform: uppercase;
    font-weight: 500;
}

.trendingItem ul li a:hover {
    color: #fff;
    border: solid 1px #dd7210;
    background-color: #dd7210;
}

.closeBtnDrop {
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #dd7210;
    cursor: pointer;
}

.trendingItem {
    position: relative;
}

.userNamePro .helll>li svg {
    color: #dd7210;
}

.searchItem .logo.mamas a {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.searchItem .logo.mamas a .text strong {
    text-transform: uppercase;
    color: #333;
    white-space: nowrap;
}

.searchItem .logo.mamas a .text p {
    text-transform: uppercase;
    color: #333;
    font-size: 13px;
    line-height: 13px;
}